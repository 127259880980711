import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { initApi } from '../../services/initApi';
import { ScreenNames } from '../../contexts/BI/interfaces';
import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const { isEditor } = flowAPI.environment;

  initApi(flowAPI);

  return {
    async pageReady() {
      try {
        const initialProps = {
          ...(await providersPropsMap({
            biSettings: {
              preventAutomaticallySendChallengeId: isEditor,
              screenName: ScreenNames.ThankYouPage,
            },
            flowAPI,
            enabledProviders: ['challengeDataProvider', 'inviteLinkProvider'],
          })),
        };

        setProps(initialProps);
      } catch (err) {
        console.error(err);
      }
    },
  };
};

export default createController;
